<template>
  <v-dialog
    v-model="value"
    max-width="600px"
    :scrollable="content.scrollable"
    :persistent="content.persistent"
  >
    <v-card>
      <v-card-title
        class="text-h3 lighten-2 pb-5"
        :style="tipoAlerta"
      >
        <span v-html="content.title" />
        <span
          v-if="content.button === ''"
          style="float:right; cursor:pointer"
          @click="fecharAlerta"
        >
          <v-icon
            class="mt-3"
            size="24"
            color="#333333"
          >
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-divider />
      <v-card-text
        v-html="content.message"
      />
      <v-divider />
      <v-card-actions class="justify-center">
        <v-btn
          v-if="content.button !== '' && content.button !== false"
          v-bind="size"
          color="blue lighten-1"
          @click="fecharAlerta"
        >
          {{ content.button }}
        </v-btn>
        <v-btn
          v-if="content.buttonYesNo !== null && content.buttonYesNo[0] !== ''"
          large
          color="blue lighten-1"
          @click="alerta('yes')"
        >
          {{ content.buttonYesNo[0] }}
        </v-btn>
        <v-btn
          v-if="content.buttonYesNo !== null && content.buttonYesNo[1] !== ''"
          large
          color="red lighten-1"
          @click="alerta('no')"
        >
          {{ content.buttonYesNo[1] }}
        </v-btn>
        <v-btn
          v-if="content.pdf"
          v-bind="size"
          color="blue lighten-3"
          @click="tryPrint()"
        >
          Download
        </v-btn>
        <v-btn
          v-if="content.print"
          v-bind="size"
          color="green lighten-1"
          @click="printAlert"
        >
          Imprimir
          <v-icon
            right
            class="mr-0"
          >
            mdi-printer
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import html2canvas from 'html2canvas'
  export default {
    name: 'PmmgDialog',
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true,
      },
      contents: {
        type: Object,
        required: true,
        default () {
          return {}
        },
      },
    },
    data: () => ({
      tipoAlerta: '',
    }),
    computed: {
      size () {
        const size = { xs: 'small', sm: 'small', lg: 'small', xl: 'small' }[this.$vuetify.breakpoint.name]
        return size ? { [size]: true } : {}
      },
      content () {
        return Object.assign({
          title: '',
          message: '',
          print: false,
          scrollable: false,
          persistent: false,
          color: null,
          button: 'Fechar',
          buttonYesNo: null,
          pdf: false,
        }, this.contents)
      },
    },
    watch: {
      content () { this.tipoAlerta = this.mudaTipoAlerta() },
    },
    methods: {
      async printAlert () {
        this.cloneDiv()
        window.print()
      },
      cloneDiv () {
        if (document.querySelector('body > #print')) { document.querySelector('body > #print').remove() }
        var printContents = document.getElementById('print')
        var cln = printContents.cloneNode(true)
        document.querySelector('body').appendChild(cln)
      },
      mudaTipoAlerta () {
        return this.content.color !== null || this.content.color !== undefined ? (this.content.color === 'success' ? 'background-color: #D8FBD8' : (this.content.color === 'error' ? 'background-color: #EF5350; color: #FFFFFF' : 'background-color:' + this.content.color)) : ''
      },
      tryPrint () {
        this.cloneDiv()
        var print = document.querySelector('.v-card__text > #print')
        document.querySelector('.v-card__text > #print > #print-header').classList.add('headerGeraJpeg')
        document.querySelector('.v-card__text > #print').classList.add('contentGeraJpeg')
        html2canvas(print, { scrollY: 0, scrollX: 0 }).then(canvas => {
          const img = canvas.toDataURL('image/jpeg', 1.0)
          var link = document.createElement('a')
          link.download = 'AGENDAVAC.jpg'
          link.href = img
          document.querySelector('.v-card__text > #print > #print-header').classList.remove('headerGeraJpeg')
          document.querySelector('.v-card__text > #print').classList.remove('contentGeraJpeg')
          link.click()
        })
      },
      fecharAlerta () {
        this.$emit('fecharAlerta')
      },
      alerta (val) {
        this.$emit('alerta', val)
      },
    },
  }
</script>

<style>
  @media print {
    @page {
      size: a4;
      margin: 0
    }
    .theme--light.v-application {
      display: none !important
    }
    *:not(body > #print, body > #print *) {
      margin:0;
      padding:0;
    }
    body {
      min-width: 20cm;
      width: 100%
    }
    body * {
      left: 0;
      top: 0;
      visibility: hidden;
      margin:0;
      padding:0;
      line-height: 1.4em
    }
    body > #print, body > #print * {
      visibility: visible
    }
    body > #print {
      left: 0;
      top: 0;
      padding: 1cm;
      font-family: "Roboto", sans-serif;
      line-height: 1.5
    }
    body > #print .text-center {
      text-align: center
    }
    body > #print > #print-header {
      display: block
    }
    body > #print > .screen-only {
      display: none !important
    }
  }
  @media screen {
    #print-header {
      display: block;
      margin-bottom: 0px !important
    }
    #print-header, .print-only {
      display: none
    }
    body > #print { display: none }
  }
  @media screen and (max-width: 639px) {
    .v-dialog .v-card .v-card__title {
      font-size: 4.5vw !important;
    }
  }
</style>
